<template>
  <v-container fluid class=" pa-4 d-flex  " style="background-color: black;" height="auto">
    <div class="row justify-content-center align-items-start " style="min-height: 100vh;">
		<v-dialog v-model="loginDialog" persistent max-width="430">
			<v-card class="gray-border" style="font-family: Arial; font-size: smaller !important; overflow: hidden;">
				<v-card-title style="font-size: 24px; justify-content: center; color:#EC6A30FF" >
						Event Portal
						<template>
							<v-sheet class="mx-auto" width="300">
								<v-form fast-fail @submit.prevent>
									<v-text-field
										v-model="username"
										:rules="usernameRules"
										style="color: black;"
										label="Username"
										color="#EC6A30FF"
										autofocus>
									</v-text-field>
									<v-text-field
										v-model="password"
										:rules="passwordRules"
										:type="showPassword ? 'text' : 'password'"
										@keyup.enter="loginClicked"
										@click:append="showPassword = !showPassword"
										style="color: #EC6A30FF; font-family: Impact;"
										label="Password"
										color="#EC6A30FF"
										append-icon="mdi-eye"
									>
									</v-text-field>
								</v-form>
							</v-sheet>
						</template>
				</v-card-title>
				<v-divider :thickness="50"></v-divider>
				<div class="pa-1" style="display: flex; justify-content: center;">
				<!-- <v-btn @click="reloadPage" class="orange-border underline-animation-black" color="red" dark style="margin-right: 5px;">
				<span style="color: black;">
					<v-icon size="30"  class="flipped-icon>
					mdi-home-export-outline
					</v-icon>
					Return
				</span>
				</v-btn> -->
					<v-btn @click="loginClicked" small class="orange-border underline-animation-black" color="#17468f" dark>
						<span style="color: white;">
							<v-icon size="30">
							mdi-login
							</v-icon>
							Log in
						</span>
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
      	<v-container fluid v-if="token" class="system_admin_background_color">
        	<div class="orange-bg system_admin_font_family_primary" style="padding:0px;">
				<v-row justify="space-between">
					<v-col>	
						<div class="fixed-container">
							<v-row class="fixed-menu system_admin_toolbar_color">
								<v-col class="d-flex align-center">
									<span class="system_admin_toolbar_color system_admin_font_toolbar_color" style="margin-left: 15px;" cols="9">Your Apps:</span>
									<v-btn plain :to="{ name: 'RaffleDraw', params: { id: encodeBase64(GET_EVENT_ID) } }" target="_blank" class="underline-animation-black" style="font-size: 13px; height: 45px;">
										<span class="system_admin_font_toolbar_color" style="color:white">Raffle Draw</span>
											<v-icon class="system_admin_icon_toolbar_color" size="24" center>
											mdi-trophy
											</v-icon>
									</v-btn>
									<v-btn plain :to="{path: `/nomination/${encodeBase64('M')}/${encodeBase64(GET_EVENT_ID)}`}" target="_blank" class="underline-animation-black" style="font-size: 13px; height: 45px;">
										<span class="system_admin_font_toolbar_color" style="color:white">MR Nominees</span>
										<v-icon size="24" class="system_admin_icon_toolbar_color" center>
										mdi-vote
										</v-icon>
									</v-btn>
									<v-btn plain :to="{path: `/nomination/${encodeBase64('F')}/${encodeBase64(GET_EVENT_ID)}`}" target="_blank" class="underline-animation-black" style="font-size: 13px; height: 45px;">
										<span class="system_admin_font_toolbar_color" style="color:white">MS Nominees</span>
										<v-icon size="24" class="system_admin_icon_toolbar_color" center>
										mdi-vote
										</v-icon>
									</v-btn>
									<v-btn plain :to="{path: `/vote_list/${encodeBase64('M')}/${encodeBase64(GET_EVENT_ID)}`}" target="_blank" class="underline-animation-black" style="font-size: 13px; height: 45px;">
										<span class="system_admin_font_toolbar_color" style="color:white">MR Vote List</span>
										<v-icon size="24" class="system_admin_icon_toolbar_color" center>
										mdi-trophy-award
										</v-icon>
									</v-btn>
									<v-btn plain :to="{path: `/vote_list/${encodeBase64('F')}/${encodeBase64(GET_EVENT_ID)}`}" target="_blank" class="underline-animation-black" style="font-size: 13px; height: 45px;">
										<span class="system_admin_font_toolbar_color" style="color:white">MS Vote List</span>
										<v-icon size="24" class="system_admin_icon_toolbar_color" center>
										mdi-trophy-award
										</v-icon>
									</v-btn>
								</v-col>
								<v-col class="text-end" cols="3">
									<v-tooltip bottom color="#5cbbf6">
										<template v-slot:activator="{ on, attrs }">
											<v-btn 
												@click="redirectToEvent()"
												v-bind="attrs"
												v-on="on"
												class="system_redirect_button_color"
												text
											>
												<v-icon>
												mdi-link-circle-outline
												</v-icon>
											</v-btn>
										</template>
										<span style="color:white;">Redirect To Event</span>
									</v-tooltip>
									<span class="system_admin_font_toolbar_color">
										{{dropdowns.event_list.find((x) => x.id == GET_EVENT_ID)?.name ?? null}}
									</span>
									<v-menu offset-y bottom left :close-on-click="true" :close-on-content-click="true">
										<template v-slot:activator="{ on, attrs }">
											<v-btn icon v-bind="attrs" v-on="on">
												<v-icon class="system_admin_icon_toolbar_color">mdi-list-box-outline</v-icon>
											</v-btn>
										</template>
										<v-list dense v-for="(event, i) in dropdowns.event_list" :key="i" color="#17468f">
											<v-list-item link @click="selectEvent(event.id,event.name)" style="color:white;" class="system_admin_font_family_primary">
												<v-icon class="system_admin_icon_toolbar_color" size="20" v-if="event.id == GET_EVENT_ID">mdi-account-check</v-icon>
												<v-icon class="system_admin_icon_toolbar_color" size="20" v-else>mdi-account</v-icon>
												<v-list-item-title class="system_admin_icon_toolbar_color"> {{event.name}} </v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
									
									<v-menu offset-y bottom left>
										<template v-slot:activator="{ on, attrs }">
											<v-btn dark icon v-bind="attrs" v-on="on">
												<v-icon class="system_admin_icon_toolbar_color">mdi-dots-vertical</v-icon>
											</v-btn>
										</template>
										
										<v-list dense color="#17468f">
										<v-list-item class="system_admin_font_family_primary">
											
											<v-list-item-title >Hello! {{ this.someOtherData.name }}</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openChangePasswordModal()" style="color:white;" class="system_admin_font_family_primary">
												<v-icon color="white" size="16">mdi-lock-reset</v-icon>
												<v-list-item-title> Change Password </v-list-item-title>
										</v-list-item>
										<v-list-item link @click="logout" style="color:white;" class="system_admin_font_family_primary">
											<v-icon color="white" size="16">mdi-logout</v-icon>
											<v-list-item-title> Logout </v-list-item-title>
										</v-list-item>
										</v-list>
									</v-menu>	
								</v-col>
							</v-row>
							<v-dialog v-model="changePasswordModal" width="50%" persistent>
								<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; " class="system_dialog_color system_admin_font_family_primary" >
												<v-card-title class="justify-content-center white--text system_dialog_color system_dialog_font_color " style="font-size: 24px;">
													<span class="system_dialog_font_color">Change Password</span>
													<v-btn @click="closeChangePasswordModal()" plain text icon color="red" class="align-start" style="left: 350px;">
														<span class="system_dialog_font_color"><v-icon class="system_dialog_font_color">mdi-window-close</v-icon></span>
													</v-btn>
												</v-card-title>
												<v-divider :thickness="50"></v-divider>
												<v-card style="background-color: black;">
													<v-card-text class="system_dialog_color">		
														<v-row no-gutters>		
															<v-col cols="12" class="px-3">				
																<v-text-field
																	label="Current Password"
																	v-model="form.currentPassword"	
																	dense
																	outlined
																	type='password'
												
																></v-text-field>
															</v-col>
														</v-row>
														<v-row no-gutters>
															<v-col cols="12" class="px-3" >
																<v-text-field
																	v-model="form.newPassword"
																	label="New Password"
																	dense
																	outlined
																	type='password'
																></v-text-field>
															</v-col>
														</v-row>
														<v-row no-gutters>
															<v-col cols="12" class="px-3" >
																<v-text-field
																	v-model="form.newPasswordConfirmation"
																	label="Confirm Password"
																	dense
																	outlined
																	type='password'
																	@keyup.enter="changePassword()"
																></v-text-field>
															</v-col>
														</v-row>

													</v-card-text>
												<v-divider :thickness="20"></v-divider>
												<div class="d-flex justify-content-center system_dialog_color">
													<v-btn class="system_dialog_button_color"  dark x-large @click="changePassword()">
														Save
													</v-btn>
												</div>
											</v-card>
								</v-card>
							</v-dialog>
								<v-row class="fixed-tabs system_sub_admin_toolbar_color">
									<v-col>
										<v-tabs v-model="tab.index" id="group-tab" class="system_sub_admin_toolbar_color system_admin_font_family_primary">
										<v-tabs-slider color="#EC6A30FF"></v-tabs-slider>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(0)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Guest List</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(1)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Company List</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(2)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Raffle Prizes</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(3)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Activities</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(4)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Program Flow</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(5, {gender: nominee_tabs[n_tab].gender}), $store.commit('GENDER', nominee_tabs[n_tab].gender);" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Event Nominees</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(6)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Event Sitemap</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(7)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Event Details</span>
											</v-tab>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(8)" :disabled="loading.datatable">
												<span class="system_sub_font_toolbar_color">Admin User</span>
											</v-tab>
											<v-spacer class="system_sub_admin_toolbar_color"></v-spacer>
											<v-tab ripple class="underline-animation-black system_sub_admin_toolbar_color" @click="getTableData(9)" :disabled="loading.datatable" v-if="user.super_admin">
												<span class="system_sub_font_toolbar_color">General Settings</span>
											</v-tab>
										</v-tabs>										
									</v-col>
								</v-row>
							</div>
						</v-col>
					</v-row>
				</div>
			<v-card>
				<div class="main-content">
					<v-tabs>
						<v-tabs-items v-model="tab.index" touchless>
					<!-- ALL Guests TAB -->
							<v-tab-item>
								<v-data-table 
									:headers="filteredHeaders" 
									:items="items" 
									:search="filterValue.guest_list_search"
									:loading="loading.datatable"
									:items-per-page="-1"
									hide-default-footer
									class="elevation-1 orange-border system_admin_table_color " 
									
								>
								<template v-slot:[`header.full_name`]="{ header }">
									<th class="system_tableheader_color">{{header.text}}</th>
								</template>
								<template v-slot:[`header.company_name`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.company_pax`]="{ header }">
									<th class="system_tableheader_color">{{header.text}}</th>
								</template>
								<template v-slot:[`header.table_num`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.time_in`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.attended`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.not_attended`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.actions`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
						
						<template v-slot:top>
							<div  style="padding:0px; font-family: Evogria;">
								<v-row justify="space-between">
									<v-col cols="2" class="system_admin_font_family_primary">
										<v-dialog v-model="addParticipantDialog" max-width="1000"  persistent>
											<template v-slot:activator="{ on, attrs }">
												<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
													<span style=" color:white;"> Add Guest </span>
													<v-icon size="24" center color="white">
														mdi-account-plus
													</v-icon>
												</v-btn>
											</template>
											<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; "height="80%" class="system_dialog_color system_admin_font_family_primary" >
												<v-card-title class="justify-content-center white--text system_dialog_color system_dialog_font_color " style="font-size: 24px;">
													<span class="system_dialog_font_color">Add Guest</span>
													<v-btn @click="toggeaddParticipantDialog" plain text icon color="red" class="align-start" style="left: 350px;">
														<span class="system_dialog_font_color"><v-icon class="system_dialog_font_color">mdi-window-close</v-icon></span>
													</v-btn>
												</v-card-title>
												<v-divider :thickness="50"></v-divider>
												<v-card style="background-color: black;">
													<v-card-text class="system_dialog_color">
														<v-container>
															<div v-if="settings.data_grouping == 1" style="display: flex; align-items: center;">
																<span style="margin-right: 10px;">Reference #:</span>
																<v-text-field v-model="generatedref" color="#17468f" disabled></v-text-field>
																<v-btn @click="generateReference" color="#17468f" plain dark small>
																	generate
																</v-btn>	
															</div>
															<div style="display: flex; align-items: center;">
																<span style="margin-left: 10px;" class="system_dialog_font_color">Last Name:</span>
																<v-text-field v-model="addParticipantLastName" @input="addParticipantLastName = addParticipantLastName.toUpperCase()" color="blue"></v-text-field>
																<v-spacer></v-spacer>
																<span style="margin-right: 10px;"class="system_dialog_font_color">First Name:</span>
																<v-text-field v-model="addParticipantFirstName" @input="addParticipantFirstName = addParticipantFirstName.toUpperCase()" color="#17468f"></v-text-field>
																<v-spacer></v-spacer>
																<span style="margin-right: 10px;"class="system_dialog_font_color">Middle Name:</span>
																<v-text-field v-model="addParticipantMiddleName" @input="addParticipantMiddleName = addParticipantMiddleName.toUpperCase()" color="#17468f"></v-text-field>
															</div>
															<div style="display: flex; align-items: center;">
																<span style="margin-right: 10px;"class="system_dialog_font_color">Company:</span>
																<v-autocomplete
																	v-model="addParticipantCompanyID"
																	:items="dropdowns.company_list"
																	item-text="name"
																	item-value="id"
																	color="#17468f"
																	class="mt-1"
																	outlined
																	auto-select-first
																	dense
																	required
																></v-autocomplete>
															</div>
															<div style="display: flex; align-items: center;">
																<span style="margin-right: 10px;"class="system_dialog_font_color">Table #:</span>
																<v-text-field v-model="addParticipantTableNumber" color="#17468f" type="number"></v-text-field>
															</div>
															
															<div style="display: flex; align-items: center;">
																<span style="margin-right: 10px;"class="system_dialog_font_color">Gender:</span>
																<v-autocomplete style="color:white;" :items="dropdowns.gender" item-value="value" item-text="text" v-model="addParticipantGender" color="#17468f"></v-autocomplete>
															</div>
															<div style="display: flex; align-items: center; justify-content:end">
																<span style="margin-right: 10px;"class="system_dialog_font_color">Include in Raffle:</span>
																<v-checkbox
																	v-model="addParticipantIncludeRaffle"
																	:true-value="1"
																	:false-value="0"
																	color="#17468f"
																	type="number"
																></v-checkbox>
															</div>
														</v-container>
													</v-card-text>
												<v-divider :thickness="20"></v-divider>
												<div class="d-flex justify-content-center system_dialog_color">
													<v-btn class="system_dialog_button_color" @click="addParticipant"  plain dark x-large :disabled="validateForm('add')">
														Add
													</v-btn>
												</div>
											</v-card>
										</v-card>
									</v-dialog>
										<v-dialog v-model="downloadParticipantsDialog" max-width="430">
											<template v-slot:activator="{ on, attrs }">
												<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
													<v-icon size="24" center color="#1976d2">
														mdi-cloud-download
													</v-icon>
												</v-btn>
											</template>
											<v-card style="font-size: smaller !important; overflow: hidden; " class="system_dialog_color system_admin_font_family_primary">
												<v-card-title class="system_dialog_color" @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
													<span class="system_dialog_font_color">Guest Event Information</span>
													<v-icon size="44" left>
														mdi-microsoft-excel
													</v-icon>
												</v-card-title>
												<v-divider :thickness="50"></v-divider>
												<div class="pa-1 system_dialog_color " style="display: flex; justify-content: center;">
													<v-btn @click="downloadExcel" class="orange-border underline-animation-black system_dialog_button_color"  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
														<span class="system_dialog_font_color">
															<v-icon size="24" left>
																mdi-download-box
															</v-icon>
																Download excel file
														</span>
													</v-btn>
												</div>
											</v-card>
										</v-dialog>
										<v-dialog v-model="uploadParticipantsDialog" max-width="50%" persistent class="system_dialog_color ">
											<template v-slot:activator="{ on, attrs }">
												<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
													<!-- <span style="font-family: Evogria; color:#17468f;">Download Data </span> -->
													<v-icon size="24" center color="#ffa500">
														mdi-cloud-upload
													</v-icon>
												</v-btn>
											</template>
											<v-card style="font-size: smaller !important; overflow: hidden;" class="pa-5 system_dialog_color system_admin_font_family_primary">
												<v-card-title class="pa-0 border-bottom system_dialog_color">
													<v-row class="m-0">
														<v-col cols="pull-right-10 p-2">
															<v-btn
																text
																icon
																small
																color="gray"
																class="float-right"
																@click="uploadParticipantsDialog = false"
															>
																<v-icon>mdi-close-circle</v-icon>
															</v-btn>
														</v-col>
													</v-row>
												</v-card-title>
												<v-card-title style="font-size: 24px; justify-content: center;">
													<v-row class="system_dialog_color">
														<v-spacer></v-spacer>
														
														<v-col class="text-center system_dialog_font_color" cols="4">
															<span class="system_dialog_font_color">Upload Guests
															<v-icon size="44" left>
															mdi-microsoft-excel
															</v-icon></span>	
														</v-col>
														<v-col class="text-end" cols="4">
															<v-tooltip bottom color="blue">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn text icon color="blue"
																		@click="downloadImportTemplate()"
																		v-bind="attrs"
																		v-on="on"
																		large
																	>
																		<v-icon
																			color="blue"
																		>mdi-download-box
																		</v-icon>
																	</v-btn>
																</template>
																<span>Download Template</span>
															</v-tooltip>
														</v-col>
													</v-row>
												</v-card-title>
												<v-divider :thickness="50"></v-divider>
												<v-row  @dragover="dragover" @dragleave="dragleave" @drop="drop" class="dropzone-container overflow-hidden system_dialog_color" v-if="batchAdditionItems.length == 0 && loaded">
													<v-col cols="12" class="text-center">
														<v-icon style="font-size:50px;" :class="have_img?'d-none':'d-block'">mdi-upload</v-icon>
														<p :class="have_img?'d-none':'d-block'"><span>Drag & Drop <p >or <label for="upload-file" style="color:blue;cursor:pointer;">browse</label> </p></span></p>
														<input type="file" id="upload-file" class="d-none" @change="ImportedExcel"
															ref="file"
															accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
													</v-col>
												</v-row>
												<v-row v-if="!loaded">
													<v-col cols="12" class="text-center system_dialog_color">
														<v-progress-circular
															:size="50"
															color="primary"
															indeterminate	
														></v-progress-circular>
														<p>{{loading_text}}</p>
													</v-col>
												</v-row>
													<v-row v-if="batchAdditionItems.length > 0">
														<v-col>														
														</v-col>
													</v-row>
												<v-card-actions>
													<v-row>
														<v-col cols="12" class="text-center" v-if="batchAdditionItems.length > 0">
															<v-btn color="#17468f" @click="submitImport()" :loading="loading.button">
																<span style="color:white;">
																	submit
																</span>
															</v-btn>
														</v-col>
													</v-row>
												</v-card-actions>
											</v-card>
										</v-dialog>
									</v-col>
									<v-col>
            							<ReportsCardComponentVue :totals="totals" :cards="cards" style="width:100%;" class="ma-2"></ReportsCardComponentVue>
            							<ReportsCardComponentVue :totals="totalsCompany" :cards="cardsCompany" style="width:100%;" class="ma-2"></ReportsCardComponentVue>
									</v-col>
									<v-col cols="2" class="mr-2 system_admin_font_family_primary">
										<v-text-field
											v-model="filterValue.guest_list_search"
											@focus="isTextFieldFocused = true"
											@blur="isTextFieldFocused = false"
											@keypress.enter="getTableData(0)"
											:class="{ 'base-color': isTextFieldFocused }"
											prepend-icon="mdi-text-search-variant"
											class="my-page orange-text-field"
											color="#17468f"
											clearable
											autofocus
											flat
										></v-text-field>
										<v-autocomplete
											v-model="filters.company_id"
											:items="dropdowns.company_list"
											@change="getTableData(0)"
											label="Company"
											item-text="name"
											item-value="id"
											clearable
											small-chips
											deletable-chips
											outlined
											auto-select-first
											multiple
											dense
											required
											class="system_admin_font_family_primary"
										>
										</v-autocomplete>
										<v-autocomplete
											v-model="filters.status"
											:items="dropdowns.status"
											@change="getTableData(0)"
											label="Status"
											item-text="text"
											item-value="value"
											clearable
											outlined
											auto-select-first
											dense
											required
											class="system_admin_font_family_primary"
										>
										</v-autocomplete>
									</v-col>                  
								</v-row>
							</div>
						</template>
							<template v-slot:body="{ items }">
							<tbody class="system_textdata_color">
								<tr v-for="(item, index) in items" :key="index">
								<td>{{ item.full_name }}</td>
								<td v-if="[1].includes(settings.data_grouping)">{{ item.employee_id }}</td>
								<td>{{ item.company_name }}</td>
								<td>{{ item.company_pax }}</td>
								<td>{{ item.table_num }}</td>
								<!-- <td>{{ item.buffet_meal_id }}</td> -->
								<!-- <td>{{ item.employment_duration_label }}</td> -->
								<td>{{ item.time_in }}</td>
								<td>
									<v-btn @click="toggleStatus(item, 2)" text icon>
										<v-icon :color="`${item.status_id == 2 ? 'green':'red'}`">{{ item.status_id == 2 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}</v-icon>
									</v-btn>
								</td>
								<td>
									<v-btn @click="toggleStatus(item, 1)" text icon>
										<v-icon :color="`${item.status_id == 1 ? 'green':'red'}`">{{ item.status_id == 1 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}</v-icon>
									</v-btn>
								</td>
								<!-- <td class="orange-text" style="font-family: Evogria;">{{ item.status_label }}</td> -->
								<td>
									<v-dialog max-width="1000"  v-model="item.showDialog" persistent>
									<template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
										<v-tooltip bottom color="#17468f">
											<template v-slot:activator="{ on:onTooltip, attrs: attrsTooltip }">
												<!-- <v-btn text icon color="#17468f"
													>
													<v-icon
														@click="editRecord(item)"
														color="#17468f"
														class="btn-action"
														small
														>mdi-square-edit-outline</v-icon
													>
												</v-btn> -->
												<v-btn text icon color="blue" v-bind="{ ...attrsMenu, ...attrsTooltip}" v-on="{ ...onMenu, ...onTooltip }">
													<v-icon class="btn-action" small>mdi-square-edit-outline</v-icon>
												</v-btn>
											</template>
											<span>Edit Record</span>
										</v-tooltip>
										
										<!-- <v-btn text icon color="red" >
										<v-icon class="btn-action" small>mdi-delete-outline</v-icon>
										</v-btn> -->
									</template>
									<v-card  style=" font-size: smaller !important; overflow: hidden;" class="system_admin_font_family_primary pt-15">
										<v-card-title class="justify-content-center white--text system_dialog_color" style="font-size: 24px; ">
											<span class="system_dialog_font_color">Guest Information</span>
											<v-btn @click="closeDialog(item)" plain text icon color="red" class="align-start" style="left: 300px;">
												<span class="system_dialog_font_color"><v-icon class="btn-action">mdi-window-close</v-icon></span>
											</v-btn>
										</v-card-title>                      
										<v-divider :thickness="50" class="system_dialog_color"></v-divider>
										<v-card	 class="system_dialog_color system_admin_font_family_primary">
											<v-card-text class="system_dialog_color">
												<v-container class="system_dialog_color">
													<div style="display: flex; align-items: center;" v-if="settings.data_grouping == 1">
													<span style="margin-right: 10px;" class="system_dialog_font_color">Reference #:</span>
													<v-text-field disabled v-model="item.employee_id" color="#17468f"></v-text-field>
													</div>
													<div style="display: flex; align-items: center;">
													<span style="margin-right: 10px;">Name:</span>
													<v-text-field disabled v-model="item.full_name" color="#17468f" ></v-text-field>
													</div>
													<!-- <div style="display: flex; align-items: center;">
													<span style="margin-right: 10px;">Tenure Status:</span>
													<v-text-field disabled v-model="item.employment_duration_label" color="#17468f"></v-text-field>
													</div> -->
													<div style="display: flex; align-items: center;">
														<span style="margin-right: 10px;">Company:</span>
														<v-autocomplete
															v-model="item.company_id"
															:items="dropdowns.company_list"
															item-text="name"
															item-value="id"
															color="#17468f"
															class="mt-1"
															outlined
															auto-select-first
															dense
															required
														></v-autocomplete>
													<!-- <v-text-field v-model="item.company_name" @input="item.company_name = item.company_name.toUpperCase()" color="#17468f"></v-text-field> -->
													</div>
													<!-- <div style="display: flex; align-items: center;">
													<span style="margin-right: 10px;">Buffet Number:</span>
													<v-text-field v-model="item.buffet_meal_id" color="#17468f" 	type="number"
													:rules="[v => !!v || 'Value is required', v => /^\d+$/.test(v) || 'Value must be a number', v => v >= 1 && v <= 3 || 'Buffet # must be between 1 and 3']"></v-text-field>
													</div> -->
													<div style="display: flex; align-items: center;">
														<span style="margin-right: 10px;">Table Number:</span>
														<v-text-field v-model="item.table_num" color="#17468f" type="number"></v-text-field>
													</div>
													<div style="display: flex; align-items: center;">
														<span style="margin-right: 10px;">Gender:</span>
														<v-autocomplete style="color:white;" :items="dropdowns.gender" item-value="value" item-text="text" v-model="item.gender" color="#17468f"></v-autocomplete>
													</div>
													<div style="display: flex; align-items: center; justify-content:end">
														<span style="margin-right: 10px;">Include in Raffle:</span>
														<v-checkbox
															v-model="item.include_raffle"
															:true-value="1"
															:false-value="0"
															color="#17468f"
															type="number"
														></v-checkbox>
													</div>
												</v-container>
											</v-card-text>
										<v-divider :thickness="20" class="system_dialog_color"></v-divider>
										<v-card-actions class="system_dialog_color system_admin_font_family_primar">
											<v-row>
												<v-col cols="12" class="text-center">
													<v-btn :disabled="validateForm('update', item)" class="system_dialog_button_color" @click="submitChanges(item.employee_data_id, item.company_id, item.table_num, item.buffet_meal_id, item.employment_duration_label, item.include_raffle, item.gender)" plain dark x-large >
														Save
													</v-btn>
												</v-col>	
											</v-row>
										</v-card-actions>
										</v-card>
									</v-card>
									</v-dialog>
								</td>
								</tr>
							</tbody>
							</template>
						</v-data-table>
						<v-btn
							@click="scrollTop"
							class="back-to-top"
							:class="{ 'show': isScrolled }"
							color="#17468f"
							bottom
							right
							fab
						>
							<v-icon color="white">mdi-arrow-up</v-icon>
						</v-btn>
					</v-tab-item>

					<!-- COMPANIES TAB -->
					<v-tab-item>
						<v-data-table 
							:headers="company_headers" 
							:items="company_items" 
							:search="filterValue.company_list"
							:loading="loading.datatable"
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
							>
								<template v-slot:[`header.code`]="{ header }">
								<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.name`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.action`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
							<template v-slot:top>
								<div class="system_admin_font_family_primary" style="padding:0px; font-family: Evogria;">
									<v-row>
										<v-col cols="2">
											<v-btn
												@click="dialogTrigger('Submit')"
												class="ml-1 mt-2 system_admin_button_color "
												>
													<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
											<v-dialog v-model="downloadCompanyDialog" max-width="440">
												<template v-slot:activator="{ on, attrs }">
													<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
														<span style="color:white;">Download Data </span>
														<v-icon size="24" center color="white">
															mdi-cloud-download
														</v-icon>
													</v-btn>
												</template>
												<v-card class="system_admin_font_family_primary" style="font-size: smaller !important; overflow: hidden;">
													<v-card-title class="system_dialog_color" @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
														Official Companies Information
														<v-icon size="44" left>
														mdi-microsoft-excel
														</v-icon>
													</v-card-title>
													<v-divider :thickness="50"></v-divider>
													<div class="pa-1 system_dialog_color system_dialog_text_color" style="display: flex; justify-content: center;">
														<v-btn @click="downloadCompaniesExcel" class="system_dialog_button_color orange-border underline-animation-black" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
														<span class="system_dialog_text_color">
															<v-icon size="24" left>
															mdi-download-box
															</v-icon>
															Download excel file
														</span>
														</v-btn>
													</div>
												</v-card>
											</v-dialog>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2">
											<v-text-field
												v-model="filterValue.ticket_num"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												@click:clear="filterCompanyByTicket()"
												:class="{ 'base-color': isTextFieldFocused }"
												placeholder="Ticket#"
												prepend-icon="mdi-ticket-percent"
												class="my-page orange-text-field "
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>  
										<v-col cols="2">
											<v-text-field
												v-model="filterValue.company_list"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												placeholder="Name"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field "
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>  
									</v-row>
								</div>
							</template>	
							<template v-slot:[`item.code`]="{ item }">
							<span class="system_textdata_color">{{ item.code }}</span>
							</template>
							<template v-slot:[`item.name`]="{ item }">
							<span class="system_textdata_color">{{ item.name }}</span>
							</template>
							<template v-slot:item.image_path="{ item }">
								<v-img class="img-thumbnail" width="50" height="50" :src="item.prize_image" eager contain></v-img>
							</template>
							<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'companyRoute')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-tab-item>

					<!-- RAFFLE PRIZES TAB -->
					<v-tab-item>
						<v-data-table 
							:headers="raffle_headers" 
							:items="items" 
							:search="filterValue.raffle_prizes"
							:loading="loading.datatable"
							class="elevation-1 orange-border system_admin_table_color"  
							
							>
								<template v-slot:[`header.image_path`]="{ header }">
								<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.code`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.name`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.winner_count`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.order`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
								<template v-slot:[`header.action`]="{ header }">
									<span class="system_tableheader_color">{{ header.text }}</span>
								</template>
							<template v-slot:top>
								<div class="" style="padding:0px; font-family: Evogria;">
									<v-row>
										<!-- <v-col cols="1">
											<v-dialog v-model="downloadParticipantsDialog" max-width="430">
												<template v-slot:activator="{ on, attrs }">
													<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
														<span style="font-family: Evogria; color:#17468f;">Download Data </span>
														<v-icon size="24" center color="#17468f">
															mdi-cloud-download
														</v-icon>
													</v-btn>
												</template>
												<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; background-color: black;">
													<v-card-title @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
														Guests Event Information
														<v-icon size="44" left>
														mdi-microsoft-excel
														</v-icon>
													</v-card-title>
													<v-divider :thickness="50"></v-divider>
													<div class="pa-1" style="display: flex; justify-content: center;">
														<v-btn @click="downloadExcel" class="orange-border underline-animation-black" color="#17468f" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
															<span style="color: black;">
																<v-icon size="24" left>
																mdi-download-box
																</v-icon>
																Download excel file
															</span>
														</v-btn>
													</div>
												</v-card>
											</v-dialog>
										</v-col> -->
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-btn
												@click="dialogTrigger('Submit')"
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
											<v-btn
												@click="orderDialog(true)"
												class="ml-2 mt-2 system_admin_button_color"
												>
												<span>
												<v-icon class="system_text_button_color">mdi-sort</v-icon>
												<span class="system_text_button_color">
													Order Prizes
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-text-field
												v-model="filterValue.raffle_prizes"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                 
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.code`]="{ item }">
							<span class="system_textdata_color">{{ item.code }}</span>
							</template>
							<template v-slot:[`item.name`]="{ item }">
							<span class="system_textdata_color">{{ item.name }}</span>
							</template>
							<template v-slot:[`item.winner_count`]="{ item }">
							<span class="system_textdata_color">{{ item.winner_count }}</span>
							</template>
							<template v-slot:[`item.order`]="{ item }">
							<span class="system_textdata_color">{{ item.order }}</span>
							</template>
							<template v-slot:item.image_path="{ item }">
								<v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
							</template>
							<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'rafflePrizes')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
						</v-data-table>
						<v-dialog v-model="orderPrizeDialog" width="60%"persistent class="">
							<v-card class="overflow-x-hidden overflow-y-auto system_admin_font_family_primary" >
								<v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color system_admin_font_family_primary">
									<span class=" h5 system_dialog_font_color system_admin_font_family_primary">Raffle Prizes Order</span>
									<v-row class="m-0">
										<v-col cols="pull-right-10 p-2">
											<v-btn
												text
												icon
												small
												color="gray"
												class="float-right"
												@click="orderDialog(false)"
											>
												<span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
											</v-btn>
										</v-col>
									</v-row>
								</v-card-title>
								<v-card-text class="system_dialog_color system_admin_font_family_primary">
									<v-container class="pb-0 mt-1 overflow-auto system_dialog_color" fluid>
										<v-simple-table class="table table-striped system_dialog_color" height="700">
											<thead class="system_tableheader_color system_admin_font_family_primary">
												<tr class="system_tableheader_color">
													<th scope="col" class="system_tableheader_color">Code</th>
													<th scope="col" class="system_tableheader_color">Name</th>
													<th scope="col" class="system_tableheader_color">Order</th>
												</tr>
											</thead>
											<draggable v-model="draggable_items" tag="tbody" >
												<tr v-for="(item, index) in draggable_items" :key="item.title" :style="tableRowStyle(item.title)" class="system_dialog_font_color">
													<td class="system_textdata_color">{{ item.code }}</td>
													<td class="system_textdata_color">{{ item.name }}</td>
													<td class="system_textdata_color">{{ index + 1 }}</td>
												</tr>
											</draggable>
											</v-simple-table>
									</v-container>
								</v-card-text>
								<v-card-actions
									class="text-center system_dialog_color"
									style="border-top: 1px solid #000000"
								>
									<v-col cols="12">
										<v-btn
											@click="saveOrder()"
											class="system_dialog_button_color"
										>
											<span class="system_dialog_font_color"> Save </span>
										</v-btn>
										<span class="pr-12">&nbsp;</span>
									</v-col>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-tab-item>
					
					<!-- ACTIVITIES TAB -->
					<v-tab-item>
						<v-data-table 
							:headers="activities_headers" 
							:items="items" 
							:search="filterValue.activities"
							:loading="loading.datatable"
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
						>
						<template v-slot:[`header.name`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.description`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.action`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
							<template v-slot:top>
								<div class="" style="padding:0px; font-family: Evogria;">
									<v-row>
										<!-- <v-col cols="1">
											<v-dialog v-model="downloadParticipantsDialog" max-width="430">
												<template v-slot:activator="{ on, attrs }">
													<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
														<span style="font-family: Evogria; color:#17468f;">Download Data </span>
														<v-icon size="24" center color="#17468f">
															mdi-cloud-download
														</v-icon>
													</v-btn>
												</template>
												<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; background-color: black;">
													<v-card-title @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
														Guests Event Information
														<v-icon size="44" left>
														mdi-microsoft-excel
														</v-icon>
													</v-card-title>
													<v-divider :thickness="50"></v-divider>
													<div class="pa-1" style="display: flex; justify-content: center;">
														<v-btn @click="downloadExcel" class="orange-border underline-animation-black" color="#17468f" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
															<span style="color: black;">
																<v-icon size="24" left>
																mdi-download-box
																</v-icon>
																Download excel file
															</span>
														</v-btn>
													</div>
												</v-card>
											</v-dialog>
										</v-col> -->
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-btn
												@click="dialogTrigger('Submit')"
												
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2 system_admin_font_family_primary">
											<v-text-field
												v-model="filterValue.activities"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                  
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.name`]="{ item }">
								<span class="system_textdata_color">{{ item.name }}</span>
							</template>
							<template v-slot:[`item.description`]="{ item }">
								<span class="system_textdata_color">{{ item.description }}</span>
							</template>
							<template v-slot:item.image_path="{ item }">
								<v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
							</template>
							<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'eventActivities')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-tab-item>

					<!-- PROGRAM FLOW TAB -->
					<v-tab-item>
						<v-data-table 
							:headers="program_flow_headers" 
							:items="items" 
							:search="filterValue.program_flow"
							:loading="loading.datatable"
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
						>
						<template v-slot:[`header.image_path`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.order`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.action`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
							<template v-slot:top>
								<div class="" style="padding:0px; font-family: Evogria;">
									<v-row>
										<!-- <v-col cols="1">
											<v-dialog v-model="downloadParticipantsDialog" max-width="430">
												<template v-slot:activator="{ on, attrs }">
													<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
														<span style="font-family: Evogria; color:#17468f;">Download Data </span>
														<v-icon size="24" center color="#17468f">
															mdi-cloud-download
														</v-icon>
													</v-btn>
												</template>
												<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; background-color: black;">
													<v-card-title @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
														Guests Event Information
														<v-icon size="44" left>
														mdi-microsoft-excel
														</v-icon>
													</v-card-title>
													<v-divider :thickness="50"></v-divider>
													<div class="pa-1" style="display: flex; justify-content: center;">
														<v-btn @click="downloadExcel" class="orange-border underline-animation-black" color="#17468f" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
															<span style="color: black;">
																<v-icon size="24" left>
																mdi-download-box
																</v-icon>
																Download excel file
															</span>
														</v-btn>
													</div>
												</v-card>
											</v-dialog>
										</v-col> -->
										<v-col cols="3" class="system_admin_font_family_primary">
											<v-btn
												@click="dialogTrigger('Submit')"
												
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
											<v-btn
												@click="flowDialogTrigger(true)"
												
												class="ml-2 mt-2 system_admin_button_color"
												>
												<span><v-icon class="system_text_button_color"> mdi-sort</v-icon>
												<span class="system_text_button_color">
													Order Program Flow
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-text-field
												v-model="filterValue.program_flow"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                  
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.order`]="{ item }">
							<span class="system_textdata_color">{{ item.order }}</span>
							</template>
							<template v-slot:item.image_path="{ item }">
								<v-img class="img-thumbnail" width="50" height="50" :src="item.flow_image" eager contain></v-img>
							</template>
							<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'programFlowImage')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
						</v-data-table>
						<v-dialog v-model="flowDialog" width="50%" persistent  class="pt-0 mt-0	" height="700">
							<v-card class="overflow-x-hidden overflow-y-auto"  >
								<v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color system_admin_font_family_primary">
									<span class="h5 system_dialog_font_color"> Program Flow Images Order</span>
									<v-row class="m-0">
										<v-col cols="pull-right-10 p-2">
											<v-btn
												text
												icon
												small
												color="gray"
												class="float-right"
												@click="flowDialogTrigger(false)"
											>
												<span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
											</v-btn>
										</v-col>
									</v-row>
								</v-card-title>
								<v-card-text class="system_dialog_color">
									<v-container class="pb-0 mt-1 overflow-auto" fluid>
										<v-simple-table class="table table-striped system_table_color system_admin_font_family_primary" height="700" width="200">
											<thead>
												<tr>
													<th scope="col">Image</th>
													<th scope="col">Order</th>
												</tr>
											</thead>
											<draggable v-model="draggable_items" tag="tbody" >
												<tr v-for="(item, index) in draggable_items" :key="item.title" :style="tableRowStyle(item.title)">
													<td> <v-img class="img-thumbnail" width="50" height="50" :src="item.flow_image" eager contain></v-img> </td>
													<td>{{ index + 1 }}</td>
												</tr>
											</draggable>
											</v-simple-table>
									</v-container>
								</v-card-text>
								<v-card-actions
									class="text-center system_dialog_color system_admin_font_family_primary"
									style="border-top: 1px solid #000000"
								>
									<v-col cols="12">
										<v-btn
											@click="saveFlowOrder()"
											class="system_dialog_button_color"
										>
											<span> Save </span>
										</v-btn>
										<span class="pr-12">&nbsp;</span>
									</v-col>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-tab-item>

					<!-- EVENT NOMINEES TAB -->
					<v-tab-item>
						<v-tabs
							v-model="n_tab"
							class="system_sub_admin_toolbar_color"
						>
							<v-tabs-slider class="system_sub_admin_toolbar_color"></v-tabs-slider>
							<v-tab
								v-for="(nominee_tab, i) in nominee_tabs"
								:key="i"
								@click="changeNomineeTab(nominee_tab.gender)"
								class="system_sub_admin_toolbar_color system_admin_font_family_primary"
							>
				
								<v-icon>{{nominee_tab.icon}}</v-icon><span style=" color:white;">{{ nominee_tab.text }}</span>
							</v-tab>
							<v-spacer class="system_sub_admin_toolbar_color"></v-spacer>
						</v-tabs>
							<v-tabs-items v-model="n_tab">
								<v-tab-item
									v-for="(nominee_tab, i) in nominee_tabs"
									:key="i"
								>
									<v-data-table 
										:headers="event_nominee_headers" 
										:items="items" 
										:search="filterValue.event_nominees"
										:loading="loading.datatable"
										:sort-by="'vote_count'"
										:sort-desc="['vote_count']"
										class="elevation-1 orange-border system_admin_table_color" 
										style="background-color: black;"
									>
									<template v-slot:[`header.full_name`]="{ header }">
										<span class="system_tableheader_color">{{ header.text }}</span>
									</template>
									<template v-slot:[`header.company_name`]="{ header }">
										<span class="system_tableheader_color">{{ header.text }}</span>
									</template>
									<template v-slot:[`header.vote_count`]="{ header }">
										<span class="system_tableheader_color">{{ header.text }}</span>
									</template>
									<template v-slot:[`header.action`]="{ header }">
										<span class="system_tableheader_color">{{ header.text }}</span>
									</template>
						
										<template v-slot:top>
											<div class="system_admin_font_family_primary" style="padding:0px; font-family: Evogria;">
												<v-row>
													<v-col cols="2">
														<v-btn
															@click="dialogTrigger('Submit')"
															
															class="ml-1 mt-2 system_admin_button_color"
															>
															<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
																<span class="system_text_button_color">
																	Add
																</span></span>
														</v-btn>
													</v-col>
													<!-- <v-autocomplete
														label="Vote Type"
														outlined
														dense
													>
													</v-autocomplete> -->
													<!-- <v-col cols="1">
														<v-dialog v-model="downloadParticipantsDialog" max-width="430">
															<template v-slot:activator="{ on, attrs }">
																<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
																	<span style="font-family: Evogria; color:#17468f;">Download Data </span>
																	<v-icon size="24" center color="#17468f">
																		mdi-cloud-download
																	</v-icon>
																</v-btn>
															</template>
															<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; background-color: black;">
																<v-card-title @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
																	Guests Event Information
																	<v-icon size="44" left>
																	mdi-microsoft-excel
																	</v-icon>
																</v-card-title>
																<v-divider :thickness="50"></v-divider>
																<div class="pa-1" style="display: flex; justify-content: center;">
																	<v-btn @click="downloadExcel" class="orange-border underline-animation-black" color="#17468f" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
																		<span style="color: black;">
																			<v-icon size="24" left>
																			mdi-download-box
																			</v-icon>
																			Download excel file
																		</span>
																	</v-btn>
																</div>
															</v-card>
														</v-dialog>
													</v-col> -->
													<!-- <v-col cols="2">
														<v-btn
															@click="dialogTrigger('Submit')"
															color="#17468f"
															class="ml-1 mt-2"
															>
																<v-icon color="white">mdi-plus</v-icon>
															<span style="color:white;">
																Add
															</span>
														</v-btn>
													</v-col> -->
													<v-spacer></v-spacer>
													<v-col cols="2 " class="system_admin_font_family_primary">
														<v-text-field
															v-model="filterValue.event_nominees"
															@focus="isTextFieldFocused = true"
															@blur="isTextFieldFocused = false"
															:class="{ 'base-color': isTextFieldFocused }"
															prepend-icon="mdi-text-search-variant"
															class="my-page orange-text-field"
															color="#17468f"
															clearable
															autofocus
															flat
														></v-text-field>
													</v-col>                  
												</v-row>
											</div>
										</template>
										<template v-slot:[`item.full_name`]="{ item }">
											<span class="system_textdata_color">{{ item.full_name }}</span>
										</template>
										<template v-slot:[`item.company_name`]="{ item }">
											<span class="system_textdata_color">{{ item.company_name }}</span>
										</template>
										<template v-slot:[`item.vote_count`]="{ item }">
											<span class="system_textdata_color">{{ item.vote_count }}</span>
										</template>
										<template v-slot:item.image_path="{ item }">
											<v-img class="img-thumbnail" width="50" height="50" :src="item.flow_image" eager contain></v-img>
										</template>
										<template v-slot:[`item.action`]="{item}">
											<v-tooltip bottom color="#17468f">
												<template v-slot:activator="{ on, attrs }">
													<v-btn text icon color="#17468f"
														v-bind="attrs"
														v-on="on"
														>
														<v-icon
															@click="dialogTrigger('View', item, 1)"
															color="#17468f"
															class="btn-action"
															small
															>mdi-eye</v-icon
														>
													</v-btn>
												</template>
												<span>View Record</span>
											</v-tooltip>
											<v-tooltip bottom color="#17468f">
												<template v-slot:activator="{ on, attrs }">
													<v-btn text icon color="#17468f"
														v-bind="attrs"
														v-on="on"
														>
														<v-icon
															@click="dialogTrigger('Update', item, 1)"
															color="blue"
															class="btn-action"
															small
															>mdi-square-edit-outline</v-icon
														>
													</v-btn>
												</template>
												<span>Edit Record</span>
											</v-tooltip>
										</template>
									</v-data-table>
								</v-tab-item>
							</v-tabs-items>
						
					</v-tab-item>

					<!-- EVENT SITEMAP TAB -->
					<v-tab-item>
						<v-data-table 
							:headers="event_sitemap_headers" 
							:items="items" 
							:search="filterValue.event_sitemap"
							:loading="loading.datatable"
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
						>
						<template v-slot:[`header.image_path`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.order`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.action`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
							<template v-slot:top>
								<div class="" style="padding:0px; font-family: Evogria;">
									<v-row>
										<!-- <v-col cols="1">
											<v-dialog v-model="downloadParticipantsDialog" max-width="430">
												<template v-slot:activator="{ on, attrs }">
													<v-btn plain v-bind="attrs" v-on="on" class="underline-animation-black mt-2" style="font-size: 13px; height: 45px;">
														<span style="font-family: Evogria; color:#17468f;">Download Data </span>
														<v-icon size="24" center color="#17468f">
															mdi-cloud-download
														</v-icon>
													</v-btn>
												</template>
												<v-card style="font-family: Evogria; font-size: smaller !important; overflow: hidden; background-color: black;">
													<v-card-title @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" style="font-size: 24px; justify-content: center;">
														Guests Event Information
														<v-icon size="44" left>
														mdi-microsoft-excel
														</v-icon>
													</v-card-title>
													<v-divider :thickness="50"></v-divider>
													<div class="pa-1" style="display: flex; justify-content: center;">
														<v-btn @click="downloadExcel" class="orange-border underline-animation-black" color="#17468f" dark @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
															<span style="color: black;">
																<v-icon size="24" left>
																mdi-download-box
																</v-icon>
																Download excel file
															</span>
														</v-btn>
													</div>
												</v-card>
											</v-dialog>
										</v-col> -->
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-btn
												@click="dialogTrigger('Submit')"
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2" class="system_admin_font_family_primary">
											<v-text-field
												v-model="filterValue.event_sitemap"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                  
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.order`]="{ item }">
								<span class="system_textdata_color">{{ item.order }}</span>
							</template>
							<template v-slot:item.image_path="{ item }">
								<v-img class="img-thumbnail" width="50" height="50" :src="item.sitemap_image" eager contain></v-img>
							</template>
							<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'eventSitemap')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-tab-item>
					<v-tab-item>
						<v-data-table 
							:headers="event_details_headers" 
							:items="items" 
							:search="filterValue.event_sitemap"
							
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
						>
						<template v-slot:[`header.code`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.name`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.link`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.action`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						
						<template v-slot:top>
								<div class="system_admin_font_family_primary" style="padding:0px;">
									<v-row>
										
										<v-col cols="2">
											<v-btn
												@click="dialogTrigger('Submit')"
												
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2">
											<v-text-field
												v-model="filterValue.event_sitemap"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                  
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.code`]="{ item }">
								<span class="system_textdata_color">{{ item.code }}</span>
							</template>
							<template v-slot:[`item.name`]="{ item }">
								<span class="system_textdata_color">{{ item.name }}</span>
							</template>
							<template v-slot:[`item.link`]="{ item }">
								<span class="system_textdata_color">{{ item.link }}</span>
							</template>
						<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'event_detail')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
					</v-data-table>
						
					</v-tab-item>
					<!-- User Details -->
					<v-tab-item>
						<v-data-table 
							:headers="user_headers" 
							:items="items" 
							:search="filterValue.event_sitemap"
							
							class="elevation-1 orange-border system_admin_table_color" 
							style="background-color: black;"
						>
						<template v-slot:[`header.code`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.name`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.link`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						<template v-slot:[`header.action`]="{ header }">
							<span class="system_tableheader_color">{{ header.text }}</span>
						</template>
						
						<template v-slot:top>
								<div class="system_admin_font_family_primary" style="padding:0px;">
									<v-row>
										
										<v-col cols="2">
											<v-btn
												@click="dialogTrigger('Submit')"
												
												class="ml-1 mt-2 system_admin_button_color"
												>
												<span class="system_text_button_color"><v-icon class="system_text_button_color" color="">mdi-plus</v-icon>
												<span class="system_text_button_color">
													Add
												</span></span>
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col cols="2">
											<v-text-field
												v-model="filterValue.event_sitemap"
												@focus="isTextFieldFocused = true"
												@blur="isTextFieldFocused = false"
												:class="{ 'base-color': isTextFieldFocused }"
												prepend-icon="mdi-text-search-variant"
												class="my-page orange-text-field"
												color="#17468f"
												clearable
												autofocus
												flat
											></v-text-field>
										</v-col>                  
									</v-row>
								</div>
							</template>
							<template v-slot:[`item.code`]="{ item }">
								<span class="system_textdata_color">{{ item.code }}</span>
							</template>
							<template v-slot:[`item.name`]="{ item }">
								<span class="system_textdata_color">{{ item.name }}</span>
							</template>
							<template v-slot:[`item.link`]="{ item }">
								<span class="system_textdata_color">{{ item.link }}</span>
							</template>
						<template v-slot:[`item.action`]="{item}">
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('View', item)"
												color="#17468f"
												class="btn-action"
												small
												>mdi-eye</v-icon
											>
										</v-btn>
									</template>
									<span>View Record</span>
								</v-tooltip>
								<v-tooltip bottom color="#17468f">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="#17468f"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												@click="dialogTrigger('Update', item)"
												color="blue"
												class="btn-action"
												small
												>mdi-square-edit-outline</v-icon
											>
										</v-btn>
									</template>
									<span>Edit Record</span>
								</v-tooltip>
								<v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteRecord(item.id, 'user_detail')"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Record</span>
								</v-tooltip>
							</template>
					</v-data-table>
						

					</v-tab-item>
					<v-tab-item v-if="user.super_admin">
						<ModuleSettingsTableComponentVue/>
					</v-tab-item>
				</v-tabs-items>
			</v-tabs>
			
		</div>
			<DialogComponent :cp="components" :hide_overlay="false" :scrollable="true" :width="height('50%')" :retain-focus="false"></DialogComponent>
        </v-card>
      </v-container>
    </div>
  </v-container>
</template>

<script>
	import axios from 'axios';
	import * as XLSX from 'xlsx';
	import moment from 'moment';

	import Swal from 'sweetalert2';
	import { defineComponent } from 'vue';
	import DialogComponent from "@/components/layouts/dialogs/Dialog.vue"
	import { mapGetters } from 'vuex';
	import SharedFunctionsComponent from '@/components/shared/SharedFunctionsComponent.vue'
	import draggable from 'vuedraggable'
	import ModuleSettingsTableComponentVue from "@/components/modules/settings/ModuleSettingsTableComponent.vue"

	import RafflePrizesComponentView from "@/components/layouts/dialogs/dashboard/RafflePrizesComponentView.vue"
	import ActivitiesComponentViewVue from '@/components/layouts/dialogs/activities/ActivitiesComponentView.vue';
	import ProgramFlowImageComponentViewVue from '@/components/layouts/dialogs/programFlow/ProgramFlowImageComponentView.vue';
	import EventDetailsView from '@/components/layouts/dialogs/EventDetails/EventDetailsComponent.vue';
	import EventNomineesComponentView from '@/components/layouts/dialogs/nominees/EventNomineesComponentView.vue'
	import EventSitemapComponentView from '@/components/layouts/dialogs/sitemap/EventSitemapComponentView.vue'
	import CompanyComponentViewVue from '@/components/layouts/dialogs/company/CompanyComponentView.vue';
	import UserDetailsComponentView from './layouts/dialogs/UserDetails/UserDetailsComponentView.vue';
	import ReportsCardComponentVue from '@/components/shared/ReportsCardComponent.vue';
	// import EventProgram from '@/components/layouts/dialogs/events/EventsProgram.vue';

	export default {
		mixins: [
			SharedFunctionsComponent
		],
		data() {
			return {
				downloadParticipantsDialog: false,
				uploadParticipantsDialog: false,
				downloadCompanyDialog: false,
				addParticipantReference: '',
				addParticipantLastName: '',
				addParticipantFirstName: '',
				addParticipantMiddleName: '',
				addParticipantTableNumber: '',
				addParticipantCompanyID: '',
				addParticipantBuffetNumber: '',
				addParticipantMobileBarTicket: '',
				addParticipantIncludeRaffle: 0,
				addParticipantGender: 0,
				generatedref: '',
				eventDetails: '',
				addParticipantDialog: false,
				loginDialog: false,
				loggedIn: false,
				isTextFieldFocused: false,
				username: '',
				hovered: false,
				addParticipantData: [],
				data: [], 
				event_details:[],
				validateKeys: [],
				password: '',
				showPassword: false,  
				items: [
					{showDialog: false },
				],
				headers: [
					{ text: 'Guest Name', align: 'start', sortable: true, value: 'full_name', grouping: [1, 2] ,class:"system_tableheader_color"},
					{ text: 'Reference #', value: 'employee_id', grouping: [1] },
					{ text: 'Company',value: 'company_name', grouping: [1, 2] },
					{ text: 'Company Pax',value: 'company_pax', grouping: [1, 2] },
					{ text: 'Table #', value: 'table_num', grouping: [1, 2] },
					// { text: 'Buffet #', value: 'buffet_meal_id' },
					// { text: 'Tenure Status', value: 'years_of_employment' },
					{ text: 'Time Registered', value: 'time_in', grouping: [1, 2] },
					{ text: 'Attended', value: 'attended', grouping: [1, 2] },
					{ text: 'Not Attended', value: 'not_attended', grouping: [1, 2] },
					// { text: 'Status', class: 'orange-text', value: 'status_id' },
					{ text: 'Actions', value:'actions', grouping: [1, 2]},
				],
				employeeDatas: [],
				tab: {
					index: 0,
					url: ['event-participants', 'companyRoute','rafflePrizes', 'eventActivities', 'programFlowImage', 'eventNominees', 'eventSitemap' ,'event_detail','user_detail','moduleSettings'],
					component: ['', CompanyComponentViewVue, RafflePrizesComponentView, ActivitiesComponentViewVue, ProgramFlowImageComponentViewVue, EventNomineesComponentView, EventSitemapComponentView ,EventDetailsView,UserDetailsComponentView,'']
				},
				raffle_headers:[
					{ text: 'Image', value: 'image_path'},,
					{ text: 'Code', value: 'code',text_class: 'system_text_button_color'}, 
					{ text: 'Name', value: 'name'},
					{ text: 'Winner Count', value: 'winner_count'},
					{ text: 'Order', value: 'order'},
					{ text: 'Action', value: 'action'},
				],
				user_headers:[
					{text: 'Name', value:'name'},
					{text: 'Username', value:'username'},
					{text: 'Super Admin', value:'super_admin'},
					{text: 'Action' , value:'action'},
				],
				activities_headers:[
					// { text: 'Image', value: 'image_path', width: '5%'},
					{ text: 'Name', value: 'name'},
					{ text: 'Description', value: 'description'},
					{ text: 'Action', value: 'action', width: '10%', align:'center'},
				],
				program_flow_headers:[
					{ text: 'Image', value: 'image_path'},
					{ text: 'Order', value: 'order'},
					{ text: 'Action', value: 'action'},
				],
				event_nominee_headers:[
					{ text: 'Full Name', value: 'full_name'},
					{ text: 'Company Name', value: 'company_name'},
					// { text: 'Nomination Count', value: 'nomination_count'},
					{ text: 'Vote Count', value: 'vote_count'},
					{ text: 'Action', value: 'action'},
				],
				event_sitemap_headers:[
					{ text: 'Image', value: 'image_path'},
					{ text: 'Order', value: 'order'},
					{ text: 'Action', value: 'action'},
				],
				event_details_headers:[
					{text:'Code', value: 'code'},
					{text:'Name', value: 'name'},
					{text:'Link', value: 'link'},
					{text:'Action', value: 'action'},
				],
				company_headers:[
					{ text: 'Code', value: 'code'},
					{ text: 'Name', value: 'name'},
					{ text: 'No. Of Tickets', value: 'no_of_tickets'},
					{ text: 'Action', value: 'action'},
				],
				items: [],
				token: localStorage.getItem("token"),
				someOtherData: JSON.parse(localStorage.getItem("someOtherData")) || {},
				components: '',
				current_index: null,
				orderPrizeDialog: false,
				changePasswordModal : false,
				draggable_items: [],
				flowDialog: false,
				dropdowns:{
					gender:[
						{text: 'Male', value: "M"},
						{text: 'Female', value: "F"},
					],
					company_list: [],
					event_list: [],
					status: [
						{text: "All", value: -1},
						{text: "Attended", value: 2 },
						{text: "Not Attended", value: 1},
					]
				},
				filterValue:{
					guest_list_search: '',
					company_list: '',
					raffle_prizes: '',
					activities: '',
					program_flow: '',
					event_nominees: '',
					event_sitemap: '',
					event_details: '',
					ticket_num: ''
				},
				nominee_tabs: [
					{ text: "Mr", gender: 'M',icon:'mdi-chess-king'},
					{ text: "Ms", gender: 'F',icon:'mdi-chess-queen'},
				],
				n_tab: 0,
				loading: {
					datatable: false,
					button: false,
				},
				filters:{
					company_id: [],
					status: null
				},
				options:{
					guest_list: {}
				},
				totalItems: 0,
				cards: [
					{ title: 'Total Pax', icon: 'mdi-account-group', icon_class: 'system_totalpax_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_pax' },
					{ title: 'Total Attended', icon: 'mdi-account-multiple-check', icon_class: 'system_totalattended_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_attended' },
					{ title: 'Not Attended', icon: 'mdi-account-multiple-minus', icon_class: 'system_notattended_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_unattended' },
					{ title: 'Total Attended %', icon: 'mdi-percent-circle-outline', icon_class: 'system_totalpercent_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_attended_percent' },
				],

				totals: {
					total_pax: 0,
					total_attended: 0,
					total_unattended: 0,
					total_attended_percent: 0,
				},
				cardsCompany: [
					{ title: 'Total Company', icon: 'mdi-account-group', icon_class: 'system_totalcompany_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_company' },
					{ title: 'Total Attended', icon: 'mdi-account-multiple-check', icon_class: 'system_totalattended_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_company_attended' },
					{ title: 'Not Attended', icon: 'mdi-account-multiple-minus', icon_class: 'system_notattended_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_company_unattended' },
					{ title: 'Total Attended %', icon: 'mdi-percent-circle-outline', icon_class: 'system_totalpercent_card_color', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: ' ', suffix: '', filter: 'currency', value: 'total_company_attended_percent' },
				],

				totalsCompany: {
					total_company: 0,
					total_company_attended: 0,
					total_company_unattended: 0,
					total_company_attended_percent: 0,
				},

				defaults: {
					status: -1,
					event_id: 2
				},
				settings: {
					data_grouping: null,
				},
				arrayBuffer:	null,
				fileList:	null,
				batchAdditionItems: [],
		        have_img:	false,
				disabled_view: false,
				loading_text: '',
				loaded: true,
				isScrolled: false,
				user:{},
				form:{
					currentPassword: '',
					newPassword: '',
					newPasswordConfirmation: '',
				},
				errors: {
					currentPassword: [],
					newPassword: [],
					confirmPassword: []
				},
				isFirstLoad: true,
				company_items: []
			};
		},
		created() {
			document.title = 'Official Guests';
		// this.fetchData();
				
			if (this.token) {
				this.loggedIn = true;
				
			}
		
		},
		async mounted(){
			this.getCompanyDropdowns();
			await this.getEventDropdowns()
			this.loginDialog = this.token ? false: true
			this.getTableData(0);
			this.getGroupingSettings();
			await this.setDefaultFilter();
			window.addEventListener('scroll', this.handleScroll);
			this.getUser();
		},
		components:{
			DialogComponent,
			RafflePrizesComponentView,
    		draggable,
			ModuleSettingsTableComponentVue,
			ProgramFlowImageComponentViewVue,
			EventSitemapComponentView,
			CompanyComponentViewVue,
			ReportsCardComponentVue,
			UserDetailsComponentView,
		},
		beforeRouteLeave(to, from, next) {
			next()
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			
		
			getUser(){
				let payload = {
					url: 'user'
				}
				this.$store.dispatch('urlGet', payload).then((res)=>{
					this.user = res;
		
				})
       		},
			openChangePasswordModal() {
				this.changePasswordModal = true; 
				this.form.currentPassword = '';
				this.form.newPassword = '';
				this.form.newPasswordConfirmation = '';
			},
			closeChangePasswordModal() {
				this.changePasswordModal = false;
				this.form.currentPassword = '';
				this.form.newPassword = '';
				this.form.newPasswordConfirmation = '';
			},
			handleScroll() {
				if (window.scrollY > 100) {
					this.isScrolled = true;
				} else {
					this.isScrolled = false;
				}
			},
			scrollTop() {
				window.scrollTo({ top: -10, behavior: 'smooth' });
			},
			closeDialog(item) {
				item.showDialog = false;
			},
			toggeaddParticipantDialog(){
				this.addParticipantDialog = !this.addParticipantDialog;
				this.generatedref = '';
				this.resetFields();
				
			},
			async submitChanges(employee_data_id, company_id, table_num, buffet_meal_id, employment_duration_label, include_raffle, gender) {
				Swal.fire({
					title: 'Submitting',
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
				});
				Swal.showLoading();
				this.data = [];
				this.data.push({
					employee_data_id: employee_data_id,
					company_id: company_id,
					table_num: table_num,
					buffet_meal_id: buffet_meal_id,
					tenure_code_id: employment_duration_label,
					include_raffle: include_raffle,
					gender: gender,
				});

				let payload = {
					url: "modify-participants",
					id: this.data,
				};

				try {
					let response = await this.$store.dispatch("urlPost", payload);
					if (response.message === "Attendee Data Updated Successfully!") {
						Swal.fire({
							title: 'UPDATED',
							text: response.message,
							icon: 'success',
							allowOutsideClick: false,
							customClass: {
								confirmButton: 'orange-button',
							}
						});
						this.$store.commit('NEW_MASTER_DATA', true);
						this.closeDialog();
						this.fetchData();
					}
				} catch (error) {
					if (error.response && error.response.status === 500) {
						Swal.fire({
							title: "<strong><u>NOT FOUND</u></strong>",
							text: error.response.data.message, 
							icon: 'warning',
							allowOutsideClick: false,
							customClass: {
								confirmButton: 'orange-button',
							}
						});
					} 
				}
			},
			

			async fetchData() {
				try {
					let response = await axios.get(process.env.VUE_APP_API_HOST + '/api/event-participants');
					this.employeeDatas = response.data.eventParticipants;
					
				} catch(error) {
					console.error(error);
				}
			},
			async changePassword() {
			if (this.form.newPassword !== this.form.newPasswordConfirmation) {
				Swal.fire('Error', 'New passwords do not match', 'error');
				return;
			}

			try {
				const payload = {
					current_password: this.form.currentPassword,
					new_password: this.form.newPassword,
					new_password_confirmation: this.form.newPasswordConfirmation
				};

				const response = await this.$store.dispatch('urlPost', { 
					url: 'changePassword', 
					...payload
				});

				
				if (response.status === 200) {
					Swal.fire('Error', 'Password change failed. Please try again.', 'error');
					this.form.currentPassword = '';
					this.form.newPassword = '';
					this.form.newPasswordConfirmation = '';
				} else {
					Swal.fire('Success', response.message || 'Password change failed. Please try again.', 'success');
					this.form.currentPassword = '';
					this.form.newPassword = '';
					this.form.newPasswordConfirmation = '';
				}
				} catch (error) {
				Swal.fire('Error', 'Password change failed. Please try again.', 'error');
				console.error("Password change failed:", error);
				
			}
			this.form.currentPassword = '';
			this.form.newPassword = '';
			this.form.newPasswordConfirmation = '';
			this.changePasswordModal = false;
		},

			async loginClicked() {
    Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    });
    Swal.showLoading();
    try {
        let responseAccess = await axios.post(`${process.env.VUE_APP_API_HOST}/api/event-portal`, {
            username: this.username,
            password: this.password,
        });

        localStorage.setItem("token", responseAccess.data.access_token);
        localStorage.setItem("someOtherData", JSON.stringify(responseAccess.data.user)); 

        Swal.close();
        this.loggedIn = true;
        this.loginDialog = false;
        this.token = localStorage.getItem("token");
        this.someOtherData = localStorage.getItem("someOtherData"); 
		window.location.reload();
        this.getTableData(0);
    } catch (error) {
        console.log('Error during login:', error);
        Swal.fire({
            icon: 'error',
            title: 'Invalid username or password',
            text: 'Please try again',
            showConfirmButton: false,
            timer: 1500,
        });
    }
},
		// async fetchDataPeriodically() {
		//   while (this.loggedIn) {
		//     try {
		//       let response = await axios.get(process.env.VUE_APP_API_HOST + '/api/event-participants');
		//       this.employeeDatas = response.data.eventParticipants;
		//     } catch (error) {
		//       console.error('Error fetching data:', error);
		//     }
		//     // Wait for 5 seconds before fetching again
		//     await new Promise(resolve => setTimeout(resolve, 20000));
		//   }
		// },
			// filterData() {
			// 	let filterText = this.filterValue.toLowerCase();
			// 	this.filteredEmployeeDatas = this.employeeDatas.filter((employee) => employee.full_name.toLowerCase().includes(filterText) || employee.employee_id.toLowerCase().includes(filterText));
			// },
			async downloadExcel() {
				let payload = {
				url: "event-participants",
				export: 1,
			}
			try {
				let response = await this.$store.dispatch("urlGet", payload);
				let employeeDatas = response.data;
				let data = employeeDatas.map(employee => ({
					'Name': employee.full_name,
					'Company': employee.company_name,
					'Table #': employee.table_num,
					'Buffet #': employee.buffet_meal_id,
					// 'Tenure Status': employee.employment_duration_label,
					'Status': employee.status_id == 1 ? 'Not Attended' : 'Attended',
					'Time Registered': employee.time_in,
				}));
				// Create a new workbook
				let wb = XLSX.utils.book_new();
				// Add the data to the workbook
				let ws = XLSX.utils.json_to_sheet(data);
				
				let payload1 = {
					url: 'companyExport'
				}
				let response1 = await this.$store.dispatch("urlPost", payload1);
				let data1 = response1.map(company => ({
					'Name': company.name,
					'Ticket Count': company.no_of_tickets,
					'Pax Count': company.pax_count,
				}));
				let ws2 = XLSX.utils.json_to_sheet(data1);
				XLSX.utils.book_append_sheet(wb, ws, 'Guests');
				XLSX.utils.book_append_sheet(wb, ws2, 'Company');

				let payload2 = {
					url: 'companyExcel'
				}
				let response2 = await this.$store.dispatch("urlPost", payload2);
				let data2 = response2.detailed.map(company => ({
					'Name': company.Name,
					'Ticket #': company.ticket_num,
				}));
				let ws3 = XLSX.utils.json_to_sheet(data2);

				XLSX.utils.book_append_sheet(wb, ws3, 'Tickets');
				// Convert the workbook to a binary Excel file
				let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
				// Create a Blob from the buffer
				let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				// Create a download link and trigger the download
				let url = window.URL.createObjectURL(blob);
				let link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.GET_EVENT_NAME.replace(" ", "").toUpperCase()}_Official_Participants.xlsx`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.downloadParticipantsDialog = false;
				Swal.showLoading();
				Swal.fire({
					title: "Check your Downloads!",
					text: "Data Downloaded Successfully!",
					icon: "success",
					allowOutsideClick: false,
					customClass: {
						confirmButton: 'orange-button',
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
			reloadPage() {
				window.location.reload();
			},
		//     async submitChanges() {
			//   Swal.fire({
			//     title: 'Saving',
			//     allowOutsideClick: false,
			//     showCancelButton: false,
			//     showConfirmButton: false,
			//   });
			//   Swal.showLoading();
			//   this.data.push({
			//     employee_id: this.item.employee_id,
			//     table_num: this.item.table_num,
			//     mobile_bar_count: this.item.mobile_bar_count,
			//   });
			//   console.log(this.data);
			//   try {
			//     axios.post(process.env.VUE_APP_API_HOST + '/api/modify_participants', this.data);
			//     this.data = [];

			//   } catch (error) {
			//   }
			// },
			async addParticipant() {
				Swal.fire({
					title: 'Saving',
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
				});
				Swal.showLoading();
				this.addParticipantData.push({
					reference_number: this.generatedref,
					first_name: this.addParticipantFirstName,
					last_name: this.addParticipantLastName,
					middle_name: this.addParticipantMiddleName,
					table_num: this.addParticipantTableNumber,
					company_id: this.addParticipantCompanyID,
					buffet_meal_id: this.addParticipantBuffetNumber,
					mobile_bar_count: this.addParticipantMobileBarTicket,
					include_raffle: this.addParticipantIncludeRaffle,
					gender: this.addParticipantGender,
				});
				let payload = {
					url: "add-participants",
					id: this.addParticipantData,
					event_id: this.GET_EVENT_ID
				};
				try {
					// let response = await axios.post(process.env.VUE_APP_API_HOST + '/api/add-participants', this.addParticipantData);
					await this.$store.dispatch("urlPost", payload)
					Swal.fire({
						title: 'SUCCESS',
						text: "Participant Added!",
						icon: 'success',
						allowOutsideClick: false,
						customClass: {
							confirmButton: 'orange-button',
						}
					});
					this.fetchData(); //call the table again
					this.toggeaddParticipantDialog();
					this.resetFields();
					this.getTableData(0);
				} catch (error) {
					if (error.response.status === 500) {
						Swal.fire({
							title: "<strong><u>Already Exists!</u></strong>",
							text: error.response.data.message, 
							icon: 'warning',
							allowOutsideClick: false,
							customClass: {
								confirmButton: 'orange-button',
							} 
						});
					} 
				}
			this.addParticipantData = [];
			},
			async getTableDataEventDetails(){
				payload

			},
			async getTableData(index = 0, other_payload = {}, isExport = 0){
				if(this.token){
					this.loading.datatable = true;
					this.items = [];
					this.company_items = [];
					let payload = {
						url: this.tab.url[index],
						...other_payload,
						...this.filters,
						...this.options,
						...this.filterValue,
						export: isExport,
						event_id: this.GET_EVENT_ID
					}
					this.current_index = index;
					this.components = this.tab.component[index];
					this.$store.dispatch('urlGet', payload).then((res)=>{			
						this.items = res.data;
						this.company_items = res.data
						this.loading.datatable = false;
						if(isExport == 0){
							Object.assign(this.totals, {
								total_attended: res.total_data.total_attended,
								total_attended_percent: (this.isFirstLoad || this.filters.status == -1) ? res.total_data.total_attended_percent : this.totals.total_attended_percent, // On first load, get value from response
								total_pax: res.total_data.total_pax,
								total_unattended: res.total_data.total_unattended,
							});

							// Update totalsCompany object
							Object.assign(this.totalsCompany, {
								total_company: res.total_data.total_company,
								total_company_attended: res.total_data.total_company_attended,
								total_company_attended_percent: (this.isFirstLoad || this.filters.status == -1) ? res.total_data.total_company_attended_percent : this.totalsCompany.total_company_attended_percent, // On first load, get value from response
								total_company_unattended: res.total_data.total_company_unattended,
							});

							this.isFirstLoad = false;

							this.getGroupingSettings();
							this.getEventDropdowns();
							
						}
					}).catch((error)=>{
						if(error.response){
							if(error.response.status == 401){
								Swal.fire({
									title: 'Unauthenticated!',
									text: '',
									icon: 'error',
									allowOutsideClick: false
								}).then((result)=>{
									if(result.isConfirmed){
										localStorage.removeItem("token");
										window.location.reload();
										this.$router.push('/');
									}
								})
							}
							
						}else{
							console.log(error)
						}
					})
				}else{
					this.loginDialog = true;
				}
			},
			height(width) {
				switch (this.$vuetify.breakpoint.name) {
					case 'xs': return "100%"
					case 'sm': return "100%"
					case 'md': return width
					case 'lg': return width
					case 'xl': return width
				}
			},
			dialogTrigger(action, item = null, request = 0){
				
				if(request == 1){
					let payload = {
						url: 'eventNominees',
						id: item.id
					}
					this.$store.dispatch('urlShow', payload).then((res)=>{
						this.$store.commit('DIALOG_VIEW', res);
						this.$store.commit('DIALOG', true);
						this.$store.commit('ACTION', action);
					})
				}else{
					this.$store.commit('DIALOG', true);
					this.$store.commit('ACTION', action);
					this.$store.commit('DIALOG_VIEW', item);
				}
				this.getEventDropdowns();
			},
			deleteRecord(id = null, url = ''){
				Swal.fire({
					title: "Delete",
					text: `Are you sure you want to delete ?`,
					icon: 'question',
					showConfirmButton:true,
					confirmButtonText:'Confirm',
					showCancelButton:true,
					showCloseButton:true,
					confirmButtonColor: '397373',
					cancelButtonColor:'grey',
					reverseButtons:true,
				})
				.then((result) => {
					if (result.isConfirmed) {
						let payload = {
							url: url,
							id: id,
							event_id: this.GET_EVENT_ID
						}
						this.$store.dispatch('urlDelete', payload).then((res)=>{
							Swal.fire(
								res.message,
								"",
								res.icon
							)
						});
					}
					this.getEventDropdowns();
					
					
				});
			},
			orderDialog(value){
				if(value){
					this.draggable_items = this.items;
				}
				this.orderPrizeDialog = value;
			},
			saveOrder(){
				this.orderDialog(false);
				this.draggable_items.forEach((x,i) =>{
					x.order = i + 1
					delete x.prize_image
				})
				let payload = {
					url: 'orderRafflePrizes',
					items: this.draggable_items,
					event_id: this.GET_EVENT_ID
				}
				this.$store.dispatch('urlPost', payload).then((response)=>{
                	this.$store.commit('NEW_MASTER_DATA', true)
					Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
				})
			},
			tableRowStyle(title) {
				let defaultStyle = 'cursor:move'
				if (title == 'National Manager' || title == 'Salesman' || title == 'Sales Coordinator') {
					defaultStyle = defaultStyle.concat(';pointer-events:none')
				}
				return defaultStyle;
			},
			flowDialogTrigger(value){
				if(value){
					this.draggable_items = this.items;
				}
				this.flowDialog = value;
			},
			saveFlowOrder(){
				this.flowDialogTrigger(false);
				this.draggable_items.forEach((x,i) =>{
					x.order = i + 1
					delete x.prize_image
				})
				let payload = {
					url: 'orderFlowImages',
					items: this.draggable_items,
					event_id: this.GET_EVENT_ID
				}
				this.$store.dispatch('urlPost', payload).then((response)=>{
                	this.$store.commit('NEW_MASTER_DATA', true)
					Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
				})
			},
			generateReference() {
			let payload = {
				url: "generate-reference-num",
			};
			try {
				this.$store.dispatch("urlGet", payload)
				.then(response => {
					let generatedReference = response.generated_reference;
					this.generatedref = generatedReference;
				});
			} catch (error) {
				console.error(error);
			}
			},
			validateForm(action, item = {}) {
				if(action == 'add'){
					if (
						!this.addParticipantLastName ||
						!this.addParticipantFirstName ||
						!this.addParticipantCompanyID ||
						!this.addParticipantGender
					) {
						return true; 
					}
					return false;
				}else{
					if(
						!item.company_id ||
						!item.gender	 ||
						!item.first_name ||
						!item.last_name
					){
						return true
					}else{
						return false;
					}
				}
			},
			resetFields(){
				this.addParticipantReference = '';
				this.addParticipantFirstName = '';
				this.addParticipantLastName = '';
				this.addParticipantMiddleName = '';
				this.addParticipantTableNumber = '';
				this.addParticipantCompanyID = '';
				this.addParticipantBuffetNumber = '';
				this.addParticipantMobileBarTicket = '';
				this.addParticipantIncludeRaffle = 0;
				this.addParticipantGender = '';
			},
			getCompanyDropdowns(){
				let payload = {
					url: 'companyRoute'
				}
				this.$store.dispatch('urlGet', payload).then((res)=>{
					this.dropdowns.company_list = res.data
				});
			},
			async getEventDropdowns(){
				let payload = {
					url: 'eventDetailDropdown'
				}
				await this.$store.dispatch('urlGet', payload).then((res)=>{
					this.dropdowns.event_list = res.data
				});
				
			},
			async downloadCompaniesExcel() {
				let payload = {
					url: "companyExcel",
				}
				try {
				await this.$store.dispatch("urlPost", payload).then((response)=>{
					let data = response

					// Create a new workbook
					let wb = XLSX.utils.book_new();
					// Add the data to the workbook
					let ws_detailed = XLSX.utils.json_to_sheet(data.detailed);
					XLSX.utils.book_append_sheet(wb, ws_detailed, 'Detailed');

					let ws_summary = XLSX.utils.json_to_sheet(data.summary);
					XLSX.utils.book_append_sheet(wb, ws_summary, 'Summary');
					// Convert the workbook to a binary Excel file
					let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
					// Create a Blob from the buffer
					let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					// Create a download link and trigger the download
					let url = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${this.GET_EVENT_NAME.replace(" ", "").toUpperCase()}_Official_Companies.xlsx`);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.downloadCompanyDialog = false;
					Swal.showLoading();
					Swal.fire({
						title: "Check your Downloads!",
						text: "Data Downloaded Successfully!",
						icon: "success",
						allowOutsideClick: false,
						customClass: {
							confirmButton: 'orange-button',
						},
					});
				});
				} catch (error) {
					console.error(error);
				}
			},
			changeNomineeTab(gender){
				let payload = {
					gender: gender
				}
				this.getTableData(5, payload);
				this.$store.commit('GENDER', gender);
			},
			logout(){
				let payload = {
					url: 'logout'
				}
				this.$store.dispatch("urlPost", payload).then(() => {

					localStorage.removeItem("token");
					localStorage.removeItem("otherData");
					window.location.reload();
				});
			},
			toggleStatus(item, status){
				if(status == 1 && item.status_id != 1){
					this.totals.total_attended --;
					this.totals.total_unattended ++;
					this.totals.total_attended_percent = (this.totals.total_attended / this.totals.total_pax * 100).toFixed(2) ;
				}else if(status == 2 && item.status_id != 2){
					this.totals.total_attended ++;
					this.totals.total_unattended --;
					this.totals.total_attended_percent = (this.totals.total_attended / this.totals.total_pax * 100).toFixed(2)
				}
				item.status_id = status;
				item.time_in = status == 1 ? null : moment().format('YYYY-MM-DD HH:mm:ss');
				let payload = {
					url: 'changeParticipantStatus',
					id: item.id,
					status_id: status
				}
				this.$store.dispatch('urlPost', payload).then((response)=>{
				})
				
			},
			async setDefaultFilter(){
				Object.assign(this.filters,{
					status: this.defaults.status
				})
				this.$store.commit('EVENT_ID', this.defaults.event_id)
				this.$store.commit('EVENT_NAME', this.dropdowns.event_list.find(x => this.defaults.event_id == x.id).name)
			},
			selectEvent(event_id,event_name){
				document.title = event_name; 
				this.$store.commit('EVENT_ID', event_id);
				this.$store.commit('EVENT_NAME', event_name);
			},
			async getGroupingSettings(){
				let payload = {
					type: 'Expansions',
					url: 'moduleSettings',
					event_id: this.GET_EVENT_ID
				};
				await this.$store.dispatch("urlGet", payload).then((res)=>{
					res.forEach(e=>{
						Object.keys(this.settings).filter(el=>{
							if(el == e.parameter){
								this.settings[el] = parseFloat(e.value)
							}
						});
					})
				})
			},
			encodeBase64(value) {
				
				return btoa(value); // Encoding value using Base64
			},
			dragover(e) {
				e.preventDefault();
				this.isDragging = true;
			},
			dragleave() {
				this.isDragging = false;
			},
			drop(e) {
				e.preventDefault();
				this.$refs.file.files = e.dataTransfer.files;
				this.ImportedExcel();
				this.isDragging = false;
			},
			ImportedExcel(event) {
				this.loaded = false
				let that = this;
				that.file = that.$refs.file.files[0];
				let fileReader = new FileReader();
				setTimeout((el)=>{
				
				fileReader.readAsArrayBuffer(that.file);
				that.loading_text = 'Transferring data please wait...'
				fileReader.onload = (e) => {
				
				that.arrayBuffer = fileReader.result;
				let data = new Uint8Array(that.arrayBuffer);
				let arr = new Array();

				for (let i = 0; i != data.length; ++i)
					arr[i] = String.fromCharCode(data[i]);
					let bstr = arr.join("");
				
					let workbook = XLSX.read(bstr, { type: "binary" });
						
					let sheetName = '';
					// let first_sheet_name = workbook.SheetNames[1];
					
					let worksheet = workbook.Sheets[workbook.SheetNames[0]];
					let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });

					that.filelist = []; 
					let tmp_arr = [];
					let tmp_obj = {};
					let tmp_obj_arr = [];
					arraylist.forEach((e,i)=>{
						that.batchAdditionItems.push({
							company_name: e['DEALER NAME'],
							last_name: e['LAST NAME'],
							first_name: e['FIRST NAME'],
							middle_name: e['MIDDLE NAME'],
							gender: e['GENDER'],
							table: e['TABLE'],
						})
					})
					that.loaded = true
					that.loading_text = 'Loading....Please Wait'
				}
				
				},2000)
				clearInterval();
        	},
			submitImport(){
				this.loading.button = true;
				let payload = {
					url: 'importGuestsFromExcel',
					items: this.batchAdditionItems,
					event_id:this.GET_EVENT_ID
				}
				this.$store.dispatch('urlPost', payload).then((res)=>{
					this.loading.button = false;
					Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
					this.$store.commit('NEW_MASTER_DATA', true)
					this.uploadParticipantsDialog = false;
					if(res.existing_guests.length > 0){
						Swal.fire({
							title: 'Following Guests Already Exist!',
							html: `<div class="swal-scrollable-content">${res.existing_guests.join('<br> ')}</div>`,
							icon: 'info'
						});
						return false
					}
				}).catch((error)=>{
					this.loading.button = false;
					if(error.response){
						if(error.response.status == 401){
							Swal.fire({
								title: 'Unauthenticated!',
								text: '',
								icon: 'error',
								allowOutsideClick: false
							}).then((result)=>{
								if(result.isConfirmed){
									localStorage.removeItem("token");
									window.location.reload();
									this.$router.push('/');
								}
							})
						}
					}else{
						console.log(error)
					}
				})
			},
			downloadImportTemplate(){
				let data = [{
					['DEALER NAME']: null,
					['LAST NAME']: null,
					['FIRST NAME']: null,
					['MIDDLE NAME']: null,
					['GENDER']: null,
					['TABLE']: null,
				}];

				let wb = XLSX.utils.book_new();
				let ws = XLSX.utils.json_to_sheet(data);
				XLSX.utils.book_append_sheet(wb, ws, 'Guests');
				let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
				let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				let url = window.URL.createObjectURL(blob);
				let link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Guest Import Template.xlsx');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			scrollTop() {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			},
			redirectToEvent(){
				let payload = {
					event_id: this.GET_EVENT_ID,
					url: 'getEventMD5'
				}
				this.$store.dispatch('urlPost', payload).then((res)=>{
					window.open(`${process.env.VUE_APP_API_HOST_CLIENT}?ec=${res.link}`)
				})
			},
			filterCompanyByTicket() {
				let ticket_num_search = this.filterValue.ticket_num ? this.filterValue.ticket_num.toLocaleLowerCase() : null;
				let company_list = this.items;

				if(ticket_num_search) {
					let filtered_company_by_ticket = company_list.filter(e => {
						let ticket_nums = e.company_tickets.filter(el => el.included_in_raffle == 1).map(x => x.ticket_num.toLocaleLowerCase());
						return ticket_nums.some(num => num.includes(ticket_num_search));
					});

					this.company_items = filtered_company_by_ticket;
				} else {
					this.company_items = this.items;
				}
			}
		},
		computed: {
			...mapGetters([
				"GET_NEW_MASTER_DATA",
				"GET_EVENT_ID",
				"GET_EVENT_NAME",
				"GET_PREFERENCES"
			]),
			usernameRules() {
				return [
				value => !!value || 'Username is required'
				];
			},
			passwordRules() {
				return [
					value => !!value || 'Password is required'
				];
			},
			filteredHeaders() {
				return this.headers.filter(header => header.grouping.includes(this.settings.data_grouping));
			},
		},
		watch:{
			GET_NEW_MASTER_DATA:{
				handler(val){
					// console.log(this.current_index)
					if(val){
						if(this.current_index == 5){
							this.getTableData(5, {gender: this.nominee_tabs[this.n_tab].gender})
							this.$store.commit('NEW_MASTER_DATA', false)
						}else{
							this.getTableData(this.current_index);
							this.getEventDropdowns()
							
							
							this.$store.commit('NEW_MASTER_DATA', false)
				
			
						}
					}
				}
			},
			'options.guest_list':{
				handler(val){
					this.getTableData(this.tab.index);
				}
			},
			GET_EVENT_ID:{
				handler(val){
					if(val){
						this.getTableData(this.tab.index);
						this.getGroupingSettings();
						this.getCompanyDropdowns();
					}
				}
			},
			'settings.data_grouping':{
				handler(val){
				}
			},
			uploadParticipantsDialog:{
				handler(val){
					if(!val){
						this.batchAdditionItems = [];
					}
				}
			},
			'filterValue.ticket_num':{
				handler(val){
					this.filterCompanyByTicket()
				},
				deep: true,
				immediate: true,
			}
		}
	}
</script>

<style>
	.orange-bg { 
		background-color: #17468f; height: 50px;
		display: inline-block; width: 100%; padding: 10px; 
	} 
	.orange-bg .v-btn, .orange-bg .v-text-field .v-text-field__slot{
		color: black; }
	.my-page .v-label  {
		color: black !important;
	}
	.flipped-icon {
		transform: scaleX(-1);
	}
	.black-text {
		color: black !important;
	}
	.black-text {
		color: black !important;
	}
	.black-text-field input {
		color: black !important;
	}
	.orange-text-field input {
		color: #17468f !important;
	}
	.Adaptive-text-LogIn {
		font-size: 22px;
		justify-content: center;
	}
	@media (max-width: 600px) {
		.Adaptive-text-Note {
			font-size: 16px; /* or any other size you prefer for smaller screens */
		}
	}
	.orange-text {
		color: rgb(29, 20, 16);
	}
	.dotted-border-orange {
		border: 1px dotted rgb(255, 255, 255);
	}
	.v-text-field--outlined >>> fieldset {
		border-color: rgba(192, 0, 250, 0.986);
	}
    .base-color {
		color: black;
	}
  	.small-header {
		padding-top: 0px; /* Adjust as needed */
		padding-bottom: 0px; /* Adjust as needed */
	}
	.sticky-header {
		position: -webkit-sticky; /* For Safari */
		position: sticky;
		top: 0;
		z-index: 999; /* Ensure it is above other table content */
		box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.4); /* Optional: Add shadow for better visibility */
	}
	.swal-scrollable-content {
		max-height: 40vh; /* Adjust the max-height as needed */
		overflow-y: auto;
	}
	.back-to-top {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 999;
	}
	.back-to-top {
		display: none; /* Initially hide the button */
	}

	.back-to-top.show {
		display: block; /* Show the button when 'show' class is applied */
	}
	.fixed-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; /* Ensure it's above other content */
	}
	.fixed-menu {
    background-color: #333; /* Background color to avoid transparency issues */
    padding: 0px; /* Adjust padding as needed */
     /* Ensure the menu is above the tabs */
}
	.fixed-tabs {
    background-color: #222; 
    padding: 0px; 
	}
	.main-content {
    margin-top: 11px; /* Adjust this value based on the combined height of the menu and tabs */
	width:100%;
}
	
</style>